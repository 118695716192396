import { createBrowserRouter } from "react-router-dom";
import {
  Uncle,
  Focus,
  NotFound,
  App,
  Landing,
  Stake,
  Claim,
} from "features/home";
export default createBrowserRouter([
  {
    path: "/app",
    element: <App />,
    children: [
      {
        path: "stake",
        element: <Stake />,
      },
      {
        path: "reward",
        element: <Claim />,
      },
      {
        path: "uncle",
        element: <Uncle />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <NotFound />,
    element: <Landing />,
  },
]);
