import React, { useState } from "react";
import ConnectWallet from "../../../components/ConnectWallet/ConnectWallet";
import {
  Grid,
  AppBar,
  CssBaseline,
  Toolbar,
  IconButton,
  Drawer,
  List,
} from "@mui/material";
import MenuItems from "./MenuItems";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "assets/img/title.svg";
import smLogo from "assets/img/logo.png";
import background from "assets/img/header_bg.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

export default function Header() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div
      style={{
        background: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: 60,
        padding: 3,
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          background: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          display: {
            md: "none",
            sm: "block",
          },
        }}
      >
        <Toolbar>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <div style={{ color: "white", fontSize: 24, padding: 5 }}>
                <img
                  src={smLogo}
                  style={{
                    height: "25px",
                    marginTop: 10,
                    objectFit: "contain",
                  }}
                />
              </div>
            </Grid>

            <Grid item>
              <ConnectWallet />
              <IconButton
                aria-label="open drawer"
                style={{ color: "white" }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>

        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          <div
            style={{
              textAlign: "center",
              width: "60vw",
              background: theme.palette.background.main,
              height: "100vh",
            }}
            className="column"
          >
            <List>
              <MenuItems handleDrawerToggle={handleDrawerToggle} />
            </List>
          </div>
        </Drawer>
      </AppBar>

      <div
        className="betweenRow"
        style={{
          padding: "0 50px",
          display: computer ? "flex" : "none",
          justifyContent: "space-between",
        }}
      >
        <div style={{ minWidth: 320 }}>
          <MenuItems />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to="/">
            <img src={logo} style={{ height: "34px", marginTop: 10 }} />
          </Link>
        </div>
        <div
          style={{ minWidth: 320, display: "flex", justifyContent: "flex-end" }}
        >
          <ConnectWallet />
        </div>
      </div>
    </div>
  );
}
