import React, { useEffect, useState, useCallback } from "react";
import CustomTabs from "components/CustomTabs/Tabs";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  Grid,
  Switch,
  FormControlLabel,
  Button,
  TextField,
} from "@mui/material";
import Card from "components/Card/Card";
import CardTitle from "components/Card/CardTitle";
import { styled } from "@mui/material/styles";
import CustomInput from "components/CustomInput/CustomInput";
import { tokens, contracts, MAX } from "features/configure";
import ButtonSteps from "./components/ButtonSteps";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import Info from "./components/Info";
import CVX from "assets/img/CVX.png";
import RewardInfo from "./components/RewardInfo";
import ReviewTx from "./components/ReviewTx";
import BigNumber from "bignumber.js";
import {
  convertAmountFromRawNumber,
  convertAmountToRawNumber,
} from "features/helpers/bignumber";
import { zeroAddress } from "viem";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Convert() {
  const [tab, setTab] = useState(0);
  const [advanced, setAdvanced] = useState(false);
  const dashboardData = useSelector((state) => state.dashboard);
  const [convertStakeAmount, setConvertStakeAmount] = useState(0);
  const [convertAmount, setConvertAmount] = useState("");
  const [stakeAmount, setStakeAmount] = useState("");
  const [unstakeAmount, setUnstakeAmount] = useState("");

  const [txData, setTxData] = useState("");

  const apiData = useSelector((state) => state.dashboard.apiData);

  const query = useQuery();
  const referBy = query.get("referBy");

  // Function to validate referBy parameter
  const isValidReferBy = (referBy) => {
    // Add your validation logic here
    return referBy && referBy.startsWith("0x") && referBy.length === 42;
  };

  const handleConvertStakeAmountChange = useCallback((value) => {
    setConvertStakeAmount(value);
  }, []);

  const handleTabChange = useCallback((value) => {
    setTab(value);
  }, []);

  const renderConverStake = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={12} md={10}>
            <div className="header">
              Convert CVX to uncleCVX. By staking uncleCVX, you’re earning the
              usual rewards from vlCVX plus all bribery rewards from the Convex
              bribe market in CVX tokens.
            </div>
            <div
              className="header"
              style={{
                marginTop: 10,
                fontStyle: "italic",
                marginBottom: 37,
                fontSize: 16,
              }}
            >
              Important: Converting CVX to uncleCVX is irreversible. You may
              stake and unstake uncleCVX tokens, but not convert them back to
              CVX. Secondary markets however exist to allow the exchange of
              uncleCVX for CVX at varying market rates.
            </div>
            <div className="header">Convert and stake CRV in one go:</div>
            <Grid container>
              <Grid item xs={12} md={6}>
                <CustomInput
                  tokens={[tokens["CVX"]]}
                  title="Amount of CVX to convert and stake"
                  balance={_.get(dashboardData, `data.balances.CVX`, 0)}
                  value={convertStakeAmount}
                  onChange={handleConvertStakeAmountChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ButtonSteps
                  steps={[
                    {
                      label: "Approve",
                      onClick: () => {
                        setTxData({
                          action: "approve",
                          message: `Approve UncleCVX contract to spend your CVX`,
                          contract: tokens["CVX"],
                          args: [tokens["uncleCVX"].address, MAX],
                        });
                      },
                    },
                    {
                      label: "Convert & Stake",
                      onClick: () => {
                        const args = isValidReferBy(referBy)
                          ? [
                              convertAmountToRawNumber(convertStakeAmount),
                              true,
                              referBy,
                            ]
                          : [
                              convertAmountToRawNumber(convertStakeAmount),
                              true,
                              zeroAddress,
                            ];

                        setTxData({
                          action: "lockCvx",
                          message: `Convert and stake ${convertStakeAmount} CVX to uncleCVX`,
                          contract: contracts["uncleCVX"],
                          args: args,
                        });
                      },
                    },
                  ]}
                  current={
                    new BigNumber(
                      _.get(
                        dashboardData,
                        `data.allowances["CVX/uncleCVX"]`,
                        "0"
                      )
                    ).isGreaterThan(0)
                      ? 1
                      : 0
                  }
                />
              </Grid>
            </Grid>
            {advanced && (
              <div>
                {" "}
                <div className="header" style={{ marginTop: 30 }}>
                  Or separately convert CRV into cvxCRV with this first form,
                  and stake cvxCRV with this second form:
                </div>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      tokens={[tokens["CVX"]]}
                      title="Amount of CVX to convert"
                      balance={_.get(dashboardData, `data.balances.CVX`, 0)}
                      value={convertAmount}
                      onChange={setConvertAmount}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ButtonSteps
                      steps={[
                        {
                          label: "Approve",
                          onClick: () => {
                            setTxData({
                              action: "approve",
                              message: `Approve UncleCVX contract to spend your CVX`,
                              contract: tokens["CVX"],
                              args: [tokens["uncleCVX"].address, MAX],
                            });
                          },
                        },
                        {
                          label: "Convert",
                          onClick: () => {
                            setTxData({
                              action: "lockCvx",
                              message: `Convert ${convertAmount} CVX to uncleCVX`,
                              contract: contracts["uncleCVX"],
                              args: [
                                convertAmountToRawNumber(convertAmount),
                                false,
                                zeroAddress,
                              ],
                            });
                          },
                        },
                      ]}
                      current={
                        new BigNumber(
                          _.get(
                            dashboardData,
                            `data.allowances["CVX/uncleCVX"]`,
                            "0"
                          )
                        ).isGreaterThan(0)
                          ? 1
                          : 0
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      tokens={[tokens["uncleCVX"]]}
                      title="Amount of CVX to convert"
                      balance={_.get(
                        dashboardData,
                        `data.balances.uncleCVX`,
                        0
                      )}
                      value={stakeAmount}
                      onChange={setStakeAmount}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ButtonSteps
                      steps={[
                        {
                          label: "Approve",
                          onClick: () => {
                            setTxData({
                              action: "approve",
                              message: `Approve UncleCVX Rewarder contract to spend your uncleCVX`,
                              contract: tokens["uncleCVX"],
                              args: [
                                contracts["uncleCVXRewarder"].address,
                                MAX,
                              ],
                            });
                          },
                        },
                        {
                          label: "Stake",
                          onClick: () => {
                            const args = [
                              convertAmountToRawNumber(stakeAmount),
                            ];
                            if (isValidReferBy(referBy)) {
                              args.push(referBy);
                            } else {
                              args.push(zeroAddress);
                            }

                            setTxData({
                              action: "stake",
                              message: `Stake ${stakeAmount} uncleCVX`,
                              contract: contracts["uncleCVXRewarder"],
                              args: args,
                            });
                          },
                        },
                      ]}
                      current={
                        new BigNumber(
                          _.get(
                            dashboardData,
                            `data.allowances["uncleCVX/uncleCvxRewarder"]`,
                            "0"
                          )
                        ).isGreaterThan(0)
                          ? 1
                          : 0
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={2} sx={{ textAlign: "right" }}>
            <CustomSwitch
              checked={advanced}
              onChange={(e) => setAdvanced(e.target.checked)}
              label={"Advanced"}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderConverUnstake = () => {
    return (
      <div style={{ padding: 22, width: "1400px" }}>
        <>
          {" "}
          <div className="header" style={{ textAlign: "center" }}>
            Unstake uncleCVX. Note that unstaked uncleCVX doesn’t earn anything:
            any and all rewards only accrue to staked uncleCVX.
          </div>
          <Grid container>
            <Grid item xs={0} md={3} />
            <Grid item xs={12} md={6}>
              <CustomInput
                tokens={[tokens["uncleCVX"]]}
                title="Amount of uncleCVX to unstake"
                balance={_.get(dashboardData, `data.staked.uncleCVX`, 0)}
                value={unstakeAmount}
                onChange={setUnstakeAmount}
                fullWidth
              />
            </Grid>
            <Grid item xs={0} md={3} />
            <Grid item xs={0} md={4} />
            <Grid
              item
              xs={12}
              md={4}
              style={{ justifyContent: "space-around", display: "flex" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setTxData({
                    action: "unstake",
                    message: `Unstake ${unstakeAmount} uncleCVX`,
                    contract: contracts["uncleCVXRewarder"],
                    args: [convertAmountToRawNumber(unstakeAmount)],
                  });
                }}
              >
                Unstake
              </Button>
            </Grid>
            <Grid item xs={0} md={4} />
          </Grid>
        </>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <Info
        rows={[
          {
            title: "CVX token address",
            href: "https://etherscan.io/address/0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
            content: "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
          },
          {
            title: "UncleCVX token address",
            href: "https://etherscan.io/address/0xeF548bB6e6D5b2F9D8b02E425726E4548e562427",
            content: "0xeF548bB6e6D5b2F9D8b02E425726E4548e562427",
          },
          {
            title: "UncleCVX Rewarder address",
            href: "https://etherscan.io/address/0x392F1af46EE8d838B04d25a722E188474F93cFEe",
            content: "0x392F1af46EE8d838B04d25a722E188474F93cFEe",
          },
          {
            title: "vlCVX contract address",
            href: "https://etherscan.io/address/0x72a19342e8F1838460eBFCCEf09F6585e32db86E",
            content: "0x72a19342e8F1838460eBFCCEf09F6585e32db86E",
          },
        ]}
      />
    );
  };

  return (
    <div style={{ maxWidth: 1400, margin: "40px auto" }}>
      {txData && <ReviewTx txData={txData} handleClose={() => setTxData("")} />}
      <Card
        switchable={false}
        expanded={true}
        title={
          <CardTitle
            data={[
              {
                label: (
                  <div className="startRow">
                    <img className="icon" src={CVX} />
                    <span style={{ marginLeft: 10 }}>CVX</span>
                  </div>
                ),
                size: 4,
              },
              { label: "$0", title: "Claimable(USD value)", size: 2 },

              {
                label: `${(_.get(apiData, "apy", 0) * 100).toFixed(2)}% | ${(
                  _.get(apiData, "maxApy", 0) * 100
                ).toFixed(2)}%`,
                title: (
                  <div>
                    Max vAPR{" "}
                    <RewardInfo
                      apr={(_.get(apiData, "apy", 0) * 100).toFixed(2)}
                      title={
                        <div className="startRow">
                          <img className="icon" src={CVX} />
                          convert CVX rewards
                        </div>
                      }
                    />
                  </div>
                ),
                size: 2,
              },
              {
                label: `${parseFloat(
                  _.get(dashboardData, `data.staked.uncleCVX`, 0)
                ).toFixed(2)} uncleCVX`,
                title: "My uncleCVX Staked",
                size: 2,
              },
              {
                label: `$${parseFloat(
                  _.get(dashboardData, `data.stats.TVL/uncleCVX`, 0)
                ).toFixed(2)}`,
                title: "TVL",
                size: 2,
              },
            ]}
          />
        }
        content={
          <div style={{ padding: "20px" }}>
            <CustomTabs
              value={tab}
              onChange={(e, newValue) => {
                handleTabChange(newValue);
              }}
              tabs={[
                { label: "CONVERT/STAKE", content: renderConverStake() },
                {
                  label: "UNSTAKE",
                  content: renderConverUnstake(),
                },
                { label: "INFO", content: renderInfo() },
              ]}
            />
          </div>
        }
      ></Card>
    </div>
  );
}
