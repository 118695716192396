import React, { useEffect } from "react";
import {
  fetchDashboard,
  fetchApiData,
  fetchReward,
  reset,
} from "./redux/dashboardSlice";
import { useDispatch } from "react-redux";
import { useAccount } from "wagmi";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import createAppTheme from "./jss/appTheme";
import Header from "./components/Header";
export default function App({ children }) {
  const theme = createAppTheme(true);
  const dispatch = useDispatch();
  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (address && isConnected) {
      dispatch(fetchDashboard({ address }));
      dispatch(fetchReward({ address }));
      dispatch(fetchApiData({}));
      const id = setInterval(() => {
        dispatch(fetchDashboard({ address }));
        dispatch(fetchReward({ address }));
        dispatch(fetchApiData());
      }, 60000);
      return () => clearInterval(id);
    } else {
      dispatch(reset());
    }
  }, [address, isConnected]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <div
        style={{
          background: theme.palette.background.main,
          minHeight: "calc(100vh - 60px)",
        }}
      >
        <div style={{ margin: "0 auto", maxWidth: 1400 }}>
          <Outlet />
        </div>
      </div>
    </ThemeProvider>
  );
}
