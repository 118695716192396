import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import {
  contracts,
  getNftUrl,
  iconsUrl,
  pirexCvxABI,
  pools,
  priceUrl,
  rewardsUrl,
  tokens,
} from "features/configure";
import axios from "axios";
import { readContracts, readContract } from "@wagmi/core";
import { convertAmountFromRawNumber } from "features/helpers/bignumber";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import BigNumber from "bignumber.js";

const GRAPHQL_ENDPOINT =
  "https://subgraph.satsuma-prod.com/09c44769217c/wasabi-team/uncle-rewarder-subgraph/version/1.0.2/api";

const query = `
query getUserStakes($address: Bytes!) {
  user(id: $address) {
    stakes {
      amount
      startTime
      endTime
    }
    referredStakes {
      amount
      startTime
      endTime
      user {
        id
      }
    }
  }
}
`;

const genesis_epoch = 1689811200;

const initialState = {
  fetchDashboardPending: false,
  fetchRewardPending: false,
  data: {},
  reward: {},
  points: {},
  apiData: {},
};

export const fetchReward = createAsyncThunk(
  "dashbaord/fetchReward",
  async ({ address }) => {
    const uncleRewarder = {
      address: contracts["uncleCVXRewarder"].address,
      abi: contracts["uncleCVXRewarder"].abi,
    };

    const uncleVesting = {
      address: contracts["uncleVesting"].address,
      abi: contracts["uncleVesting"].abi,
    };

    let contractData = [];
    contractData.push({
      ...uncleRewarder,
      functionName: "claimableReward",
      args: [address],
    }); //0 -> claimable cvx

    contractData.push({
      ...uncleRewarder,
      functionName: "unclePendingReward",
      args: [address],
    }); //1 -> claimable uncle

    contractData.push({
      ...uncleVesting,
      functionName: "getVestings",
      args: [address],
    }); // 2 -> vesting uncle

    const data = await readContracts({
      contracts: contractData,
    });

    const priceData = await axios.get(priceUrl + tokens["CVX"].address);
    const cvxPrice = _.get(
      priceData,
      `data.coins[ethereum:${tokens["CVX"].address}].price`,
      0
    );

    const rewards = {};
    let vestings;
    const price = {};
    const stats = {};

    rewards["CVX"] = convertAmountFromRawNumber(
      _.get(data, `[${0}].result`, 0)
    );

    rewards["UNCLE"] = convertAmountFromRawNumber(
      _.get(data, `[${1}].result`, 0)
    );

    vestings = _.get(data, `[${2}].result`, []);

    price["CVX"] = parseFloat(cvxPrice);
    price["UNCLE"] = 0.5;

    stats["claimable"] = // rewards["CVX"] * price["CVX"] + rewards["UNCLE"] * price["UNCLE"];
      parseFloat(
        new BigNumber(rewards["CVX"])
          .times(new BigNumber(price["CVX"]))
          .plus(
            new BigNumber(rewards["UNCLE"]).times(new BigNumber(price["UNCLE"]))
          )
          .toString()
      );

    return { rewards, vestings, price, stats };
  }
);

export const fetchDashboard = createAsyncThunk(
  "dashbaord/fetchDashboard",
  async ({ address }) => {
    try {
      const cvxContract = {
        address: tokens["CVX"].address,
        abi: tokens["CVX"].abi,
      };
      const uncleCVXContract = {
        address: contracts["uncleCVX"].address,
        abi: contracts["uncleCVX"].abi,
      };
      const uncleRewarderContract = {
        address: contracts["uncleCVXRewarder"].address,
        abi: contracts["uncleCVXRewarder"].abi,
      };

      let contractData = [];
      contractData.push({
        ...cvxContract,
        functionName: "balanceOf",
        args: [address],
      }); //0

      contractData.push({
        ...cvxContract,
        functionName: "allowance",
        args: [address, contracts["uncleCVX"].address],
      }); //1

      contractData.push({
        ...uncleCVXContract,
        functionName: "balanceOf",
        args: [address],
      }); //2

      contractData.push({
        ...uncleCVXContract,
        functionName: "allowance",
        args: [address, contracts["uncleCVXRewarder"].address],
      }); //3

      contractData.push({
        ...uncleRewarderContract,
        functionName: "stakedBalances",
        args: [address],
      }); //4

      contractData.push({
        ...uncleRewarderContract,
        functionName: "unclePendingReward",
        args: [address],
      }); //5

      contractData.push({
        ...uncleRewarderContract,
        functionName: "claimableReward",
        args: [address],
      }); //6

      contractData.push({
        ...uncleCVXContract,
        functionName: "totalSupply",
      }); //7

      const data = await readContracts({
        contracts: contractData,
      });

      const balances = {};
      const allowances = {};
      const staked = {};
      const rewards = {};
      const stats = {};
      balances["CVX"] = convertAmountFromRawNumber(
        _.get(data, `[${0}].result`, 0)
      );
      balances["uncleCVX"] = convertAmountFromRawNumber(
        _.get(data, `[${2}].result`, 0)
      );

      allowances["CVX/uncleCVX"] = convertAmountFromRawNumber(
        _.get(data, `[${1}].result`, 0)
      );
      allowances["uncleCVX/uncleCvxRewarder"] = convertAmountFromRawNumber(
        _.get(data, `[${3}].result`, 0)
      );

      staked["uncleCVX"] = convertAmountFromRawNumber(
        _.get(data, `[${4}].result`, 0)
      );

      rewards["uncleCVX/uncle"] = convertAmountFromRawNumber(
        _.get(data, `[${5}].result`, 0)
      );

      rewards["uncleCVX/cvx"] = convertAmountFromRawNumber(
        _.get(data, `[${6}].result`, 0)
      );

      stats["uncleCVXTotalSupply"] = convertAmountFromRawNumber(
        _.get(data, `[${7}].result`, 0)
      );

      const priceData = await axios.get(priceUrl + cvxContract.address);
      const cvxPrice = _.get(
        priceData,
        `data.coins[ethereum:${cvxContract.address}].price`,
        0
      );

      stats["TVL/uncleCVX"] = convertAmountFromRawNumber(
        new BigNumber(_.get(data, `[${7}].result`, 0)).times(
          new BigNumber(cvxPrice)
        )
      );

      return {
        balances,
        allowances,
        staked,
        rewards,
        stats,
      };
    } catch (err) {
      enqueueSnackbar(_.get(err, "message"), {
        variant: "error",
      });
      throw err;
    }
  }
);

const handleRewardData = (data, rewards, rewardDetails, type) => {
  if (!data) return;
  for (let i = 0; i < data.length; i++) {
    const epochData = data[i];
    for (let d of epochData) {
      if (!rewards[d.address]) {
        rewards[d.address] = { value: 0 };
      }
      if (!rewardDetails[d.epoch]) {
        rewardDetails[d.epoch] = { snapshotRewards: [], futuresRewards: [] };
      }
      if (d.isClaimed) continue;
      if (d.rewardAmount == 0) continue;
      rewards[d.address]["value"] += parseFloat(
        convertAmountFromRawNumber(
          d.rewardAmount,
          d.address == "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48" ? 6 : 18
        )
      );
      rewardDetails[d.epoch][type].push(d);
    }
  }
};

const getLastBiweeklyThursday = () => {
  const startDate = new Date("2024-06-06T08:00:00+08:00");
  const currentDate = new Date();

  // 計算從起始日期到現在經過了多少個兩週週期
  const twoWeekCycles = Math.floor(
    (currentDate - startDate) / (14 * 24 * 60 * 60 * 1000)
  );

  // 計算最接近現在時間的上一個週四
  const lastThursday = new Date(startDate);
  lastThursday.setDate(startDate.getDate() + twoWeekCycles * 14);

  // 調整日期到週四
  const dayOfWeek = lastThursday.getDay();
  if (dayOfWeek !== 4) {
    lastThursday.setDate(lastThursday.getDate() - (dayOfWeek - 4));
  }

  return new Date(lastThursday);
};

export const fetchApiData = createAsyncThunk(
  "dashbaord/fetchApiData",
  async ({}) => {
    try {
      let ts = getLastBiweeklyThursday();
      // convert to timestamp
      ts = Math.floor(ts.getTime() / 1000);

      let lobbyURL = `https://mainnet-api.lobby.page/lobby-bribe/gauge_round_reward_info_list?bribe_type=vlcvx&page=1&size=100&start_time=${ts}`;
      let response = await axios.get(lobbyURL);

      const apys = _.get(response, "data.data.list", []);

      let avgApy = _.meanBy(apys, (o) => o.apy);
      let maxApy = _.maxBy(apys, (o) => o.apy);

      return { apy: avgApy, maxApy: maxApy.apy };
    } catch (err) {
      enqueueSnackbar(_.get(err, "message"), {
        variant: "error",
      });
      throw err;
    }
  }
);

export const fetchPointData = createAsyncThunk(
  "dashboard/fetchPointData",
  async ({ address }) => {
    try {
      const response = await axios.post(GRAPHQL_ENDPOINT, {
        query: query,
        variables: {
          address: address.toLowerCase(),
        },
      });

      if (!response.data.data.user) {
        return {
          userPoints: 0,
          referPoints: 0,
        };
      }

      const data = response.data.data.user;
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

      let userPoints = new BigNumber(0);
      let referPoints = new BigNumber(0);

      // Calculate points for stakes
      data.stakes.forEach((stake) => {
        const endTime = stake.endTime
          ? new BigNumber(stake.endTime)
          : new BigNumber(currentTime);
        const hoursStaked = endTime
          .minus(new BigNumber(stake.startTime))
          .dividedBy(3600)
          .integerValue(BigNumber.ROUND_FLOOR);
        userPoints = userPoints.plus(
          new BigNumber(stake.amount).dividedBy(1e18).multipliedBy(hoursStaked)
        );
      });

      // Calculate points for referred stakes
      data.referredStakes.forEach((stake) => {
        const endTime = stake.endTime
          ? new BigNumber(stake.endTime)
          : new BigNumber(currentTime);
        const hoursStaked = endTime
          .minus(new BigNumber(stake.startTime))
          .dividedBy(3600)
          .integerValue(BigNumber.ROUND_FLOOR);
        referPoints = referPoints.plus(
          new BigNumber(stake.amount)
            .dividedBy(1e18)
            .multipliedBy(hoursStaked)
            .multipliedBy(0.1)
        );
      });

      return {
        userPoints: parseFloat(userPoints.toString()),
        referPoints: parseFloat(referPoints.toString()),
      };
    } catch (error) {
      // ignore data is null

      enqueueSnackbar(_.get(error, "message"), {
        variant: "error",
      });
      throw error;
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: () => initialState,
    // todoDeleted(state, action) {
    //   delete state.entities[action.payload];
    // },
    // todoToggled(state, action) {
    //   const todoId = action.payload;
    //   const todo = state.entities[todoId];
    //   todo.completed = !todo.completed;
    // },
    // allTodosCompleted(state, action) {
    //   Object.values(state.entities).forEach((todo) => {
    //     todo.completed = true;
    //   });
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.pending, (state, action) => {
        state.fetchDashboardPending = true;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.data = action.payload;
        state.fetchDashboardPending = false;
      })
      .addCase(fetchDashboard.rejected, (state, action) => {
        state.fetchDashboardPending = false;
      })
      .addCase(fetchApiData.pending, (state, action) => {
        state.fetchApiPending = true;
      })
      .addCase(fetchApiData.fulfilled, (state, action) => {
        state.apiData = action.payload;
        state.fetchApiPending = false;
      })
      .addCase(fetchApiData.rejected, (state, action) => {
        state.fetchApiPending = false;
      })
      .addCase(fetchReward.pending, (state, action) => {
        state.fetchRewardPending = true;
      })
      .addCase(fetchReward.fulfilled, (state, action) => {
        state.reward = action.payload;
        state.fetchRewardPending = false;
      })
      .addCase(fetchReward.rejected, (state, action) => {
        state.fetchRewardPending = false;
      })
      .addCase(fetchPointData.pending, (state, action) => {
        state.fetchPointDataPending = true;
      })
      .addCase(fetchPointData.fulfilled, (state, action) => {
        state.points = action.payload;
        state.fetchPointDataPending = false;
      })
      .addCase(fetchPointData.rejected, (state, action) => {
        state.fetchPointDataPending = false;
      });
  },
});

export const { reset } = dashboardSlice.actions;
export default dashboardSlice.reducer;
