import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";

const MenuItems = ({ handleDrawerToggle }) => {
  const location = useLocation();
  const theme = useTheme();
  const [selected, setSelected] = useState(
    _.replace(location.pathname, "/", "")
  );

  useEffect(() => {
    setSelected(_.replace(location.pathname, "/", ""));
  }, [location]);

  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const renderButton = (text) => {
    const isSelected = text == selected;

    return (
      <Link to={"/app/" + text}>
        <Button
          variant="contained"
          size="small"
          color={isSelected ? "secondary" : "primary"}
        >
          {_.upperFirst(text)}
        </Button>
      </Link>
    );
  };
  return (
    <div className={computer ? "row" : "column"}>
      {renderButton("stake")}
      {renderButton("reward")}
      {renderButton("uncle")}
    </div>
  );
};

export default MenuItems;
