import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import twitter from "assets/img/twitter.svg";
import gitbook from "assets/img/gitbook.svg";
import telegram from "assets/img/telegram.svg";
import wTwitter from "assets/img/wTwitter.svg";
import wGitbook from "assets/img/wGitbook.svg";
import wTelegram from "assets/img/wTelegram.svg";
const Social = ({ white = false }) => {
  return (
    <div className="row">
      <IconButton
        style={{ margin: 15 }}
        onClick={() => window.open("https://x.com/unclebond_cvx")}
      >
        <img src={white ? wTwitter : twitter} />
      </IconButton>
      <IconButton
        style={{ margin: 15 }}
        onClick={() => window.open("https://t.me/unclebond_cvx")}
      >
        <img src={white ? wTelegram : telegram} />
      </IconButton>
      <IconButton
        style={{ margin: 15 }}
        onClick={() =>
          window.open("https://uncle-bond.gitbook.io/uncle-tool-shop")
        }
      >
        <img src={white ? wGitbook : gitbook} />
      </IconButton>
    </div>
  );
};

export default Social;
