import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import landingBg from "assets/img/landing_bg.png";
import title from "assets/img/title.svg";
import { Grid, useMediaQuery, Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import createAppTheme from "./jss/appTheme";
import ConnectWallet from "components/ConnectWallet/ConnectWallet";
import Social from "./components/Social";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import _ from "lodash";
import { fetchDashboard, fetchApiData } from "./redux/dashboardSlice";

export default function Landing() {
  const dispatch = useDispatch();
  const { address, isConnected } = useAccount();
  useEffect(() => {
    if (address && isConnected) {
      dispatch(fetchDashboard({ address }));
      dispatch(fetchApiData({}));
    }
  }, [address, isConnected]);

  const dashboardData = useSelector((state) => state.dashboard);
  const apiData = useSelector((state) => state.dashboard.apiData);

  const theme = createAppTheme(true);
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: "100vh", background: "#F76356" }}>
        <div
          style={{
            margin: "0 auto",
            maxWidth: "1200px",
            textAlign: "center",
            position: "relative",
            background: `url(${landingBg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "90vh",
            borderRadius: "0px 0px 30px 30px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
          >
            <img src={title} alt="title" />
            <Social />
          </div>
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
          >
            <ConnectWallet text />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "90%",
              left: "5%",
            }}
          >
            <div style={{ padding: 10 }}>
              <Grid container alignItems={"center"}>
                <Grid item xs={12} md={4} style={{ textAlign: "left" }}>
                  <div style={{ color: "white" }} className="startRow">
                    <div style={{ marginRight: 40 }}>
                      <div style={{ textAlign: "right", fontSize: 20 }}>
                        APY
                      </div>
                      <div
                        className="cardTitle"
                        style={{ fontFamily: "Poppin" }}
                      >
                        {(apiData.maxApy * 100).toFixed(2)}%
                      </div>
                    </div>
                    <div>
                      <div style={{ textAlign: "right", fontSize: 20 }}>
                        TVL
                      </div>
                      <div
                        className="cardTitle"
                        style={{ fontFamily: "Poppin" }}
                      >
                        {`$${_.get(
                          dashboardData,
                          `data.stats.TVL/uncleCVX`,
                          0
                        )}`}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={8} style={{ textAlign: "right" }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    component={Link}
                    to="/app/stake"
                    size={computer ? "large" : "medium"}
                  >
                    Stake
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    component={Link}
                    to="/app/reward"
                    size={computer ? "large" : "medium"}
                  >
                    Reward
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    component={Link}
                    to="/app/uncle"
                    size={computer ? "large" : "medium"}
                  >
                    Uncle
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
