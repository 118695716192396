import React from "react";
import primary from "assets/img/primary.png";
import _ from "lodash";
const PoolHeader = ({ logo, header, infos, labels }) => {
  const renderInfo = (header, value, index) => {
    return (
      <div style={{ textAlign: "right", marginLeft: 40 }} key={index}>
        <div style={{ fontSize: 20, fontWeight: 300 }}>{header}</div>
        <div style={{ fontSize: 24, fontWeight: 600 }}>{value}</div>
      </div>
    );
  };
  const renderLabel = (label, icon, index) => {
    return (
      <div
        key={index}
        style={{
          background: "rgba(255, 255, 255, 0.4)",
          marginRight: 15,
          marginTop: 24,
          borderRadius: 50,
          padding: "7px 10px",
          paddingRight: 17,
          fontWeight: 500,
          fontSize: 20,
          color: "#04283B",
        }}
        className="startRow"
      >
        <img
          src={icon}
          style={{ width: 36, height: 36, marginRight: 8, borderRadius: 50 }}
        />{" "}
        {label}
      </div>
    );
  };

  return (
    <div
      style={{
        padding: 44,
        background: `url(${primary})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "#04283B",
        borderRadius: "0 0 10px 10px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div className="betweenRow">
        <div>
          <div style={{ fontSize: 50, fontWeight: 700 }} className="startRow">
            <img src={logo} style={{ width: 72, marginRight: 15 }} /> {header}
          </div>
          <div className="startRow">
            {_.map(labels, (label, i) => {
              return renderLabel(label.label, label.icon, i);
            })}
          </div>
        </div>
        <div className="endRow">
          {_.map(infos, (info, i) => {
            return renderInfo(info.header, info.value, i);
          })}
        </div>
      </div>
    </div>
  );
};

export default PoolHeader;
