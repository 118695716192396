import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "features/home/redux/dashboardSlice";

const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
  },
});

export default store;
