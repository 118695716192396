import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAccount } from "wagmi";
import _ from "lodash";
import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Stats from "./components/Stats";
import Card from "components/Card/Card";
import CardTitle from "components/Card/CardTitle";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import { contracts, tokens } from "features/configure";
import ReviewTx from "./components/ReviewTx";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { convertAmountFromRawNumber } from "features/helpers/bignumber";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { fetchPointData } from "./redux/dashboardSlice";

export default function Claim() {
  const [claimStake, setClaimStake] = useState(true);
  const [txData, setTxData] = useState("");

  const rewardData = useSelector((state) => state.dashboard.reward);
  const depositData = useSelector((state) => state.dashboard);
  const pointData = useSelector((state) => state.dashboard.points);
  const apiData = useSelector((state) => state.dashboard.apiData);
  const account = useAccount();

  const dispatch = useDispatch();

  const { address, isConnected } = useAccount();
  useEffect(() => {
    if (address && isConnected) {
      dispatch(fetchPointData({ address }));
    }
  }, [address, isConnected]);

  const getVestingAndVested = (vestings) => {
    // vestings is an array of vesting objects with the following structure
    // {amount: 1607000000000000000000n, startTime: 1710512694n, endTime: 1718288694n}
    // vested is the sum of all the vested amounts, which means the end time has passed
    // vesting is the sum of all the vesting amounts, which means the end time has not passed
    let allVested = 0;
    let allVesting = 0;
    const current = new Date().getTime() / 1000;
    _.map(vestings, (vesting) => {
      if (parseInt(vesting.endTime.toString()) < current) {
        allVested += parseFloat(
          convertAmountFromRawNumber(vesting.amount.toString())
        );
      } else {
        allVesting += parseFloat(
          convertAmountFromRawNumber(vesting.amount.toString())
        );
        console.log("vesting", vesting);
      }
    });

    return { vested: allVested, vesting: allVesting };
  };

  const renderReward = (reward) => {
    return (
      <Card
        title={
          <CardTitle
            data={[
              {
                label: (
                  <div className="startRow tableHeader">
                    <img className="icon" src={reward.imgUrl} />
                    {reward.name}
                  </div>
                ),
                size: 2,
              },
              {
                label: `$${parseFloat(
                  _.get(rewardData, "stats.claimable", 0)
                ).toFixed(2)}`,
                title: "Claimable(USD value)",
                size: 3,
              },
              {
                label: `${(_.get(apiData, "apy", 0) * 100).toFixed(2)}% | ${(
                  _.get(apiData, "maxApy", 0) * 100
                ).toFixed(2)}% + UNCLE points`,
                title: "vAPR",
                size: 3,
              },
              { label: "", title: "", size: 2 },
              {
                label: (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    sx={{ width: "174px" }}
                    onClick={() => {
                      setTxData({
                        action: "claimReward",
                        message: `Claim all rewards`,
                        contract: contracts["uncleCVXRewarder"],
                        args: [],
                      });
                    }}
                  >
                    Claim All
                  </Button>
                ),
                size: 2,
              },
            ]}
          />
        }
        content={reward.content}
      ></Card>
    );
  };
  const renderVestingPool = (vestingPool) => {
    console.log("vestingPool", vestingPool);
    return (
      <Card
        title={
          <CardTitle
            data={[
              {
                label: (
                  <div className="startRow tableHeader">
                    <img className="icon" src={vestingPool.imgUrl} />
                    {vestingPool.name}
                  </div>
                ),
                size: 3,
              },
              {
                label: `${parseFloat(vestingPool.vesting).toFixed(2)}`,
                title: "Vesting",
                size: 3,
              },
              {
                label: `${parseFloat(vestingPool.vested).toFixed(2)}`,
                title: "Vested",
                size: 2,
              },
              { label: "", title: "", size: 2 },
              {
                label: (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    sx={{ width: "174px" }}
                  >
                    Open
                  </Button>
                ),
                size: 2,
              },
            ]}
          />
        }
        content={vestingPool.content}
      ></Card>
    );
  };
  const renderTokenList = (tokenList) => {
    return (
      <div style={{ padding: 22 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid container item xs={12} style={{ marginBottom: "10px" }}>
            <Grid item xs={2}>
              <strong>Reward</strong>
            </Grid>
            <Grid item xs={2}>
              <strong>Amount</strong>
            </Grid>
            <Grid item xs={2}>
              <strong>Price</strong>
            </Grid>
            <Grid item xs={2}>
              <strong>USD Value</strong>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          {_.map(tokenList, (token, i) => {
            return (
              <Fragment key={i}>
                <Grid item xs={2}>
                  <div className="startRow">
                    <img className="icon" src={token.imgUrl} />
                    {token.symbol}
                  </div>
                </Grid>
                <Grid item xs={2}>
                  {parseFloat(token.amount).toFixed(2)}
                </Grid>
                <Grid item xs={2}>
                  ${token.price}
                </Grid>
                <Grid item xs={2}>
                  ${parseFloat(token.usdValue).toFixed(2)}
                </Grid>
                <Grid items xs={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    sx={{ width: "174px", margin: "20px 0" }}
                    onClick={() => {
                      setTxData({
                        action: "claimReward",
                        message: `Claim ${token.symbol} rewards`,
                        contract: contracts["uncleCVXRewarder"],
                        args: [],
                      });
                    }}
                  >
                    Claim
                  </Button>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </div>
    );
  };
  const renderVestingList = (tokenList) => {
    return (
      <div style={{ padding: 22 }}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid container item xs={12} style={{ marginBottom: "10px" }}>
            <Grid item xs={2}>
              <strong>Amount</strong>
            </Grid>
            <Grid item xs={2}>
              <strong>USD Value</strong>
            </Grid>
            <Grid item xs={2}>
              <strong>Start</strong>
            </Grid>
            <Grid item xs={2}>
              <strong>End</strong>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          {_.map(tokenList, (token, i) => {
            return (
              <Fragment key={i}>
                <Grid item xs={2}>
                  {parseFloat(
                    convertAmountFromRawNumber(token.amount.toString())
                  ).toFixed(2)}
                </Grid>
                <Grid item xs={2}>
                  {(
                    parseFloat(convertAmountFromRawNumber(token.amount)) *
                    _.get(rewardData, "price.UNCLE", 0)
                  ).toFixed(2)}
                </Grid>
                <Grid item xs={2}>
                  {moment(parseInt(token.startTime.toString()) * 1000).format(
                    "YYYY/MM/DD "
                  )}
                </Grid>
                <Grid item xs={2}>
                  {moment(parseInt(token.endTime.toString()) * 1000).format(
                    "YYYY/MM/DD "
                  )}
                </Grid>
                <Grid items xs={4}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    sx={{ width: "174px", margin: "20px 0" }}
                    disabled={
                      parseInt(token.endTime.toString()) >
                      new Date().getTime() / 1000
                    }
                  >
                    Claim
                  </Button>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </div>
    );
  };

  const renderClaimAllField = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <div className="startRow">
          <CustomSwitch
            label={<span className="listHeader">Claim All & Stake</span>}
            checked={claimStake}
            onChange={(e) => {
              setClaimStake(e.target.checked);
            }}
          />
          <div className="listHeader" style={{ color: "#F83B53" }}>
            Claim and stake all your rewards in one go
          </div>
        </div>
        {claimStake && (
          <div className="card">
            <div className="listHeader">CLAIM ALL AND STAKE</div>
            <div style={{ fontStyle: "italic", color: "#8D8D8D" }}>
              Select some rewards in order to claim them
            </div>
            <Button
              color="primary"
              variant="contained"
              size="small"
              sx={{ width: "174px", margin: "20px 0" }}
            >
              Claim All
            </Button>
          </div>
        )}
      </div>
    );
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://uncle.bond/app/stake?referBy=${account.address}`
    );
  };

  const renderReferralLink = () => {
    return (
      <div style={{ padding: 22 }}>
        <TextField
          fullWidth
          value={`https://uncle.bond/app/stake?referBy=${account.address}`}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleCopy}>
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div style={{ maxWidth: 1400 }}>
        {txData && (
          <ReviewTx txData={txData} handleClose={() => setTxData("")} />
        )}
        <Stats
          claimable={parseFloat(
            _.get(rewardData, "stats.claimable", 0)
          ).toFixed(2)}
          deposits={(
            parseFloat(_.get(depositData, "data.staked.uncleCVX", 0)) *
            _.get(rewardData, "price.CVX", 0)
          ).toFixed(2)}
          unclePoints={pointData}
        />

        <div
          className="fieldHeader"
          style={{ marginBottom: 10, color: "white", fontSize: "20px" }}
        >
          Claim earnings
        </div>
        {_.map(
          [
            {
              name: "uncleCVX Pools",
              imgUrl: require("assets/img/uncleCVX.png"),
              claimable: 0,
              apr: 2.45,
              content: renderTokenList([
                {
                  ...tokens.CVX,
                  amount: _.get(rewardData, "rewards.CVX", 0),
                  price: _.get(rewardData, "price.CVX", 0),
                  usdValue:
                    _.get(rewardData, "rewards.CVX", 0) *
                    _.get(rewardData, "price.CVX", 0),
                },
              ]),
            },
          ],
          (reward) => {
            return renderReward(reward);
          }
        )}
        <Card
          title={"Your Referral Link"}
          expanded
          content={renderReferralLink()}
        ></Card>

        {/* <>
          <div className="fieldHeader" style={{ marginBottom: 10 }}>
            Uncle vestings
          </div>
          {_.map(
            [
              {
                name: "UNCLE Vestings",
                imgUrl: require("assets/img/UNCLE.png"),
                vested: getVestingAndVested(_.get(rewardData, "vestings", []))[
                  "vested"
                ],
                vesting: getVestingAndVested(_.get(rewardData, "vestings", []))[
                  "vesting"
                ],
                content: renderVestingList(_.get(rewardData, "vestings", [])),
              },
            ],
            (vesting) => {
              return renderVestingPool(vesting);
            }
          )}
        </> */}
      </div>
    </>
  );
}
