import Uncle from "assets/img/UNCLE.png";
import uncleCVX from "assets/img/uncleCVX.png";
import CVX from "assets/img/CVX.png";
import { pxCvxABI, uCvxABI, cvxABI, uncleCVXABI } from "./abi";
export const tokens = {
  uncleCVX: {
    symbol: "uncleCVX",
    address: "0xeF548bB6e6D5b2F9D8b02E425726E4548e562427",
    imgUrl: uncleCVX,
    abi: uncleCVXABI,
  },
  focusCVX: {
    symbol: "focuxCVX",
    address: "0xe4aF32D9d5ADdF6D786Ed6b50C8d76c7D7541A02",
    imgUrl: uncleCVX,
    abi: uCvxABI,
  },
  CVX: {
    symbol: "CVX",
    address: "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
    imgUrl: CVX,
    abi: cvxABI,
  },
  UNCLE: {
    symbol: "UNCLE Points",
    address: "0x00000000000000000000000000000000000000000",
    imgUrl: Uncle,
    abi: pxCvxABI,
  },
};
