import React, { useEffect, useRef, useState } from "react";
import { useWeb3Modal } from "@web3modal/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Button from "@mui/material/Button";
import { useAccount, useContract } from "wagmi";
import { getShortAddress } from "features/helpers/utils";

const ConnectWallet = ({ text }) => {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { open, close } = useWeb3Modal();

  return <ConnectButton />;
};

export default ConnectWallet;
