import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, TextField, CircularProgress } from "@mui/material";
import { fetchReward } from "./redux/dashboardSlice";
import eth from "assets/img/eth.png";
import CVX from "assets/img/CVX.png";
import logo from "assets/img/uncleCVX.png";
import CustomTabs from "components/CustomTabs/Tabs";
import CustomInput from "components/CustomInput/CustomInput";
import { tokens, contracts, MAX } from "features/configure";
import CustomModal from "components/CustomModal/CustomModal";
import { useAccount } from "wagmi";
import moment from "moment";
import _ from "lodash";
import ReviewTx from "./components/ReviewTx";
import Redemption from "./components/Redemption";
import PoolHeader from "./components/PoolHeader";
import {
  convertAmountFromRawNumber,
  convertAmountToRawNumber,
} from "features/helpers/bignumber";
import BigNumber from "bignumber.js";
import { checkAllowance, getShortAddress } from "features/helpers/utils";
import CustomTable from "components/CustomTable/CustomTable";
import RewardDetails from "./components/RewardDetails";
import { useWeb3Modal } from "@web3modal/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Convert from "./Convert";
export default function Stake() {
  const dashboardData = useSelector((state) => state.dashboard);
  const apiData = useSelector((state) => state.dashboard.apiData);

  // console.log("dashboardData", dashboardData);
  // const rewardData = useSelector((state) => state.dashboard.reward);
  // const fetchRewardPending = useSelector(
  //   (state) => state.dashboard.fetchRewardPending
  // );
  // console.log("rewardData", rewardData);
  // const { address, isConnected } = useAccount();
  // const [selectedSwapToken, setSelectedSwapToken] = useState(
  //   tokens["uncleCVX"]
  // );
  // const { open } = useWeb3Modal();
  // const dispatch = useDispatch();
  // const [selectedInfo, setSelectedInfo] = useState(0);
  // const [slippage, setSlippage] = useState(0.5);

  // const [selectedUserInfo, setSelectedUserInfo] = useState(0);
  // const [openSwap, setOpenSwap] = useState(false);
  // const [openRewardDetail, setOpenRewardDetail] = useState(false);
  // const [depositInput, setDepositInput] = useState("");
  // const [swapInput, setSwapInput] = useState("");
  // const [txData, setTxData] = useState("");

  // const [isFirstLoad, setIsFirstLoad] = useState(true);

  // useEffect(() => {
  //   if (!address) {
  //   } else {
  //     dispatch(fetchReward({ address }));

  //     const id = setInterval(() => {
  //       dispatch(fetchReward({ address }));
  //     }, 10000);
  //     return () => clearInterval(id);
  //   }
  // }, [address]);

  // useEffect(() => {
  //   if (!fetchRewardPending && isFirstLoad) {
  //     setIsFirstLoad(false);
  //   }
  // }, [fetchRewardPending]);

  // const renderSwapModal = () => {
  //   const isCVX = selectedSwapToken.symbol == "CVX";
  //   const from = isCVX ? 0 : 1;
  //   const to = isCVX ? 1 : 0;
  //   return (
  //     <CustomModal
  //       open={openSwap}
  //       setOpen={setOpenSwap}
  //       title={"Swap"}
  //       content={
  //         <div>
  //           <CustomInput
  //             tokens={[tokens["uncleCVX"], tokens["CVX"]]}
  //             value={swapInput}
  //             selectedToken={selectedSwapToken}
  //             setSelectedToken={setSelectedSwapToken}
  //             onChange={setSwapInput}
  //             balance={_.get(
  //               dashboardData,
  //               `data.balances.${selectedSwapToken.symbol}`,
  //               0
  //             )}
  //           />
  //           <div className="lightCard">
  //             <div style={{ marginBottom: 25 }}>Slippage:</div>
  //             <Grid container alignItems={"center"}>
  //               <Grid item xs={12} lg={8}>
  //                 <CustomTabs
  //                   value={slippage}
  //                   fullWidth
  //                   onChange={(e, newValue) => setSlippage(newValue)}
  //                   tabs={[
  //                     {
  //                       label: "0.1%",
  //                       value: "0.1",
  //                     },
  //                     {
  //                       label: "0.5%",
  //                       value: "0.5",
  //                     },
  //                     {
  //                       label: "1.0%",
  //                       value: "1",
  //                     },
  //                   ]}
  //                 />
  //               </Grid>
  //               <Grid item xs={12} lg={4}>
  //                 <TextField
  //                   value={slippage}
  //                   onChange={(e) => setSlippage(e.target.value)}
  //                   type="number"
  //                   variant="standard"
  //                   inputProps={{ min: 0, style: { textAlign: "right" } }}
  //                   InputProps={{ disableUnderline: true, endAdornment: "%" }}
  //                 />
  //               </Grid>
  //             </Grid>
  //           </div>
  //           {_.get(
  //             dashboardData,
  //             `data.allowances[${selectedSwapToken.symbol}/lpxCVX"]`,
  //             0
  //           ) > 0 ? (
  //             <Button
  //               variant="contained"
  //               style={{ marginTop: 20 }}
  //               fullWidth
  //               disabled={!swapInput || slippage > 90 || slippage < 0}
  //               color="primary"
  //               onClick={() => {
  //                 setTxData({
  //                   action: "swap",
  //                   message: `Swap ${swapInput} ${
  //                     selectedSwapToken.symbol
  //                   } for ~${new BigNumber(swapInput)
  //                     .multipliedBy(
  //                       _.get(
  //                         dashboardData,
  //                         `data.preview[${selectedSwapToken.symbol}Swap]`,
  //                         0
  //                       )
  //                     )
  //                     .toFormat(4)} ${
  //                     isCVX ? "uncleCVX" : "CVX"
  //                   } through Curve`,
  //                   contract: tokens["lpxCVX"],
  //                   args: [
  //                     isCVX ? 0 : 1,
  //                     convertAmountToRawNumber(swapInput),
  //                     new BigNumber(convertAmountToRawNumber(swapInput))
  //                       .multipliedBy(1 - slippage / 100)
  //                       .toFixed(4),
  //                     from,
  //                     to,
  //                   ],
  //                 });
  //               }}
  //             >
  //               Swap
  //             </Button>
  //           ) : isConnected ? (
  //             <Button
  //               variant="contained"
  //               style={{ marginTop: 20 }}
  //               fullWidth
  //               color="primary"
  //               onClick={() => {
  //                 setTxData({
  //                   action: "approve",
  //                   contract: contracts["lpxCVX"],
  //                   args: [contracts["lpxCVX"].address, MAX],
  //                 });
  //               }}
  //             >
  //               Approve
  //             </Button>
  //           ) : (
  //             <ConnectButton />
  //           )}
  //         </div>
  //       }
  //     />
  //   );
  // };

  // const cvxAllowance = _.get(dashboardData, "data.allowances[CVX/PirexCVX]", 0);
  // const cvxBalance = _.get(dashboardData, "data.balances.CVX", 0);
  // const rewards = _.get(rewardData, "rewards", []);
  // const redemptionList = _.get(dashboardData, "apiData.redemptionList", []);
  // const renderRewardDetail = () => {
  //   return (
  //     <CustomModal
  //       open={openRewardDetail}
  //       setOpen={setOpenRewardDetail}
  //       title={"Convex Rewards"}
  //       content={<RewardDetails setTxData={setTxData} />}
  //     />
  //   );
  // };

  // const renderRedemptions = () => {
  //   if (
  //     _.get(dashboardData, "fetchApiPending") &&
  //     !_.get(dashboardData, "apiData")
  //   ) {
  //     return (
  //       <div className="column" style={{ height: 200 }}>
  //         <CircularProgress />
  //       </div>
  //     );
  //   }
  //   if (redemptionList.length == 0)
  //     return (
  //       <div className="lightCard">
  //         No Redemptions for {getShortAddress(address, 4)}
  //       </div>
  //     );
  //   const now = moment();

  //   return (
  //     <div>
  //       <CustomTable
  //         headers={["Unlock", "Amount", ""]}
  //         contents={_.map(redemptionList, (redemption) => {
  //           const unlockTime = moment(
  //             new BigNumber(_.get(redemption, "id.tokenId")).toNumber() * 1000
  //           );
  //           return [
  //             moment(
  //               new BigNumber(_.get(redemption, "id.tokenId")).toNumber() * 1000
  //             ).format("YYYY/MM/DD"),
  //             new BigNumber(
  //               convertAmountFromRawNumber(redemption.balance)
  //             ).toFormat(2),
  //             <Button
  //               variant="contained"
  //               size="small"
  //               disabled={now.isBefore(unlockTime)}
  //               color="primary"
  //               onClick={() => {
  //                 setTxData({
  //                   action: "redeem",
  //                   contract: contracts["PirexCVX"],
  //                   args: [
  //                     [redemption.id.tokenId],
  //                     [redemption.balance],
  //                     address,
  //                   ],
  //                 });
  //               }}
  //             >
  //               Redeem
  //             </Button>,
  //           ];
  //         })}
  //       />
  //     </div>
  //   );
  // };

  // const renderRewards = () => {
  //   if (
  //     _.get(dashboardData, "fetchApiPending") &&
  //     !_.get(dashboardData, "apiData")
  //   ) {
  //     return (
  //       <div className="column" style={{ height: 200 }}>
  //         <CircularProgress />
  //       </div>
  //     );
  //   }
  //   if (fetchRewardPending && isFirstLoad)
  //     return (
  //       <div className="column" style={{ height: 200 }}>
  //         <CircularProgress />
  //       </div>
  //     );

  //   if (rewards.length == 0)
  //     return (
  //       <div className="lightCard">
  //         No rewards for {getShortAddress(address, 4)}
  //       </div>
  //     );

  //   return (
  //     <div>
  //       <CustomTable
  //         headers={["Reward", "Amount", "Value"]}
  //         contents={_.map(
  //           _.filter(rewards, (r) => {
  //             return new BigNumber(_.get(r, "value", 0)).toNumber() > 0;
  //           }),
  //           (reward) => {
  //             return [
  //               <div className="startRow">
  //                 <img src={_.get(reward, "coin.icon")} className="smIcon" />
  //                 {_.get(reward, "coin.name")}
  //               </div>,
  //               new BigNumber(_.get(reward, "value", 0)).toFormat(3),
  //               `$${new BigNumber(_.get(reward, "value", 0))
  //                 .multipliedBy(_.get(reward, "price", 0))
  //                 .toFormat(3)}`,
  //             ];
  //           }
  //         )}
  //       />
  //       <Button
  //         variant="contained"
  //         style={{ marginTop: 20 }}
  //         fullWidth
  //         onClick={() => {
  //           setOpenRewardDetail(true);
  //         }}
  //         color="primary"
  //       >
  //         Show Claims
  //       </Button>
  //     </div>
  //   );
  // };
  return (
    <div>
      {/* {renderSwapModal()}
      {renderRewardDetail()}
      {txData && <ReviewTx txData={txData} handleClose={() => setTxData("")} />} */}
      <PoolHeader
        header="uncleCVX"
        logo={logo}
        labels={[
          { label: "CONVEX", icon: CVX },
          { label: "Ethereum", icon: eth },
        ]}
        infos={[
          {
            header: "APY",
            value: `${(_.get(apiData, "maxApy", 0) * 100).toFixed(2)}%`,
          },
          {
            header: "TVL",
            value: `$${_.get(dashboardData, `data.stats.TVL/uncleCVX`, 0)}`,
          },
          { header: "Peg for $10k", value: "-" },
          {
            header: "Total Staked",
            value: `${parseFloat(
              _.get(dashboardData, "data.stats.uncleCVXTotalSupply", 0)
            )} CVX`,
          },
        ]}
      />

      <Grid container spacing={2}>
        <Convert />
        {/* <Grid item xs={12} md={7}>
          <div className="card">
            <CustomTabs
              title={"Vault Info"}
              value={selectedInfo}
              onChange={(e, newValue) => setSelectedInfo(newValue)}
              tabs={[
                {
                  label: "Strategy",
                  content: (
                    <div className="cardDesc">
                      The vault secures the user's CVX within Convex and grants
                      them vlCVX every 16 weeks. This vlCVX earns bribe rewards
                      through integration with LobbyDAO and Focus, which are
                      then compounded into additional focusCVX.
                    </div>
                  ),
                },
                {
                  label: "Fees",
                  content: <div className="cardDesc">Fees</div>,
                },
              ]}
            />
          </div>
          <div className="card">
            <CustomTabs
              title={"User Info"}
              value={selectedUserInfo}
              onChange={(e, newValue) => setSelectedUserInfo(newValue)}
              tabs={[
                {
                  label: "Rewards",
                  content: (
                    <div className="cardDesc">
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={5}>
                          Rewards
                          <br />
                          <br /> Depositors are eligible to claim rewards for
                          their <b>uncleCVX</b> holdings at the epoch timestamp.
                          Rewards are distributed based on a snapshot of{" "}
                          <b> uncleCVX</b> balances, taken at the timestamp of
                          each epoch. Claims for RFN and pending unlocks are
                          also available here.
                        </Grid>
                        <Grid item xs={12} md={7}>
                          {renderRewards()}
                        </Grid>
                      </Grid>
                    </div>
                  ),
                },
                {
                  label: "Redemptions",
                  content: (
                    <div className="cardDesc">
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={5}>
                          Redemptions
                          <br />
                          <br /> Your uuncleCVX holdings represent burned
                          uncleCVX used to initiate redemption of underlying
                          CVX. You can redeem for underlying CVX anytime after
                          the unlock date.
                        </Grid>
                        <Grid item xs={12} md={7}>
                          {renderRedemptions()}
                        </Grid>
                      </Grid>
                    </div>
                  ),
                },
              ]}
            />
          </div>
          {/* <div className="card">
            <div className="cardTitle">Historical Data</div>
          </div> 
        </Grid>
        <Grid item xs={12} md={5}>
          <div className="card">
            <div className="cardTitle">Deposit</div>
            <CustomInput
              tokens={[tokens["CVX"]]}
              value={depositInput}
              onChange={setDepositInput}
              balance={cvxBalance}
            />
            {checkAllowance(cvxAllowance, depositInput) ? (
              <Button
                variant="contained"
                style={{ marginTop: 20 }}
                fullWidth
                color="primary"
                onClick={() => {
                  setTxData({
                    message: `Deposit ${depositInput} CVX for ~${depositInput} uncleCVX`,
                    action: "deposit",
                    contract: contracts["PirexCVX"],
                    args: [
                      convertAmountToRawNumber(depositInput),
                      address,
                      false,
                      "0x0000000000000000000000000000000000000000",
                    ],
                  });
                }}
              >
                Deposit
              </Button>
            ) : isConnected ? (
              <Button
                variant="contained"
                style={{ marginTop: 20 }}
                fullWidth
                onClick={() => {
                  setTxData({
                    action: "approve",
                    contract: tokens["CVX"],
                    args: [contracts["PirexCVX"].address, MAX],
                  });
                }}
                color="primary"
              >
                Approve
              </Button>
            ) : (
              <ConnectButton />
            )}
          </div>
          <div className="card">
            <div className="cardTitle">Redemptions</div>
            <div className="cardDesc">
              Initiate a withdrawal of CVX from the protocol by burning
              uncleCVX. Until the redemption date, rewards can still be claimed.
            </div>
            <Redemption setTxData={setTxData} />
          </div>
          <div className="card">
            <div className="cardTitle">Swap</div>
            <div className="cardDesc">
              Exchange <b>uncleCVX</b> for <b>CVX</b> via Curve pool.
            </div>
            {isConnected ? (
              <Button
                variant="contained"
                style={{ marginTop: 20 }}
                fullWidth
                disabled
                onClick={() => {
                  setOpenSwap(true);
                }}
                color="primary"
              >
                Swap
              </Button>
            ) : (
              <ConnectButton />
            )}
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
}
