import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import Uncle from "assets/img/UNCLE.png";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

import _ from "lodash";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const Stats = ({ claimable, deposits, unclePoints }) => {
  const StyledCard = styled((props) => <div {...props} />)({
    borderRadius: 20,
    height: 155,
    color: "#FFF",
    fontWeight: 600,
    fontSize: 24,
    textAlign: "center",
    padding: 30,
    letterSpacing: 5,
    position: "relative",
  });
  const StyledValue = styled((props) => <div {...props} />)({
    fontSize: 24,
  });

  return (
    <div style={{ margin: "15px auto", maxWidth: 1000 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <StyledCard style={{ background: "#F83B53" }}>
            Total Clamable<StyledValue>${claimable}</StyledValue>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledCard style={{ background: "#229FFC" }}>
            Total Deposits<StyledValue>${deposits}</StyledValue>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledCard style={{ background: "#f76356" }}>
            <img className="icon" src={Uncle} />
            Uncle Points
            <StyledValue>
              {(
                _.get(unclePoints, "userPoints", 0) +
                _.get(unclePoints, "referPoints", 0)
              ).toFixed(2)}
              <Tooltip
                title={
                  <div>
                    <div>
                      User Points:{" "}
                      {_.get(unclePoints, "userPoints", 0).toFixed(2)}
                    </div>
                    <div>
                      Refer Points:{" "}
                      {_.get(unclePoints, "referPoints", 0).toFixed(2)}
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon style={{ marginLeft: 10, cursor: "pointer" }} />
              </Tooltip>
            </StyledValue>
          </StyledCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default Stats;
