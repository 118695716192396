import React, { useState } from "react";
import { Modal, Box } from "@mui/material";
import secondary from "assets/img/secondary.png";
import loadingBg from "assets/img/loading_bg.png";
import { fetchDashboard, fetchReward } from "../redux/dashboardSlice";
import { useDispatch } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import { useContractWrite, usePrepareContractWrite, useAccount } from "wagmi";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
const style = {
  position: "absolute",
  top: "50%",
  color: "#FFFFFF",
  textAlign: "center",
  left: "50%",
  overflow: "auto",
  maxHeight: "90%",
  transform: "translate(-50%, -50%)",
  maxWidth: "550px",
  background: `url(${secondary})`,
  boxShadow: 24,
  borderRadius: "10px",
  p: "45px",
};

export default function ReviewTx({ txData, handleClose, callback }) {
  const { action, contract, args, value = 0, message } = txData;
  const theme = useTheme();
  console.log("txData", txData);
  const { config } = usePrepareContractWrite({
    address: _.get(contract, "address"),
    abi: contract.abi,
    functionName: action,
    args: args,
  });

  console.log("config", config);

  const { address } = useAccount();
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess, write, isError, error } =
    useContractWrite(config);

  const renderContent = () => {
    if (isSuccess) {
      callback && callback();
      dispatch(fetchDashboard({ address }));
      dispatch(fetchReward({ address }));
      enqueueSnackbar("Success", {
        variant: "success",
      });
      handleClose();
      return <div />;
    }
    if (isError) {
      return (
        <Box sx={style}>
          <div className="cardTitle">Transaction Failed</div>
          <div style={{ fontSize: "20px", fontWeight: 400 }}>
            {action} {_.get(contract, "symbol", "")}
          </div>
          <div
            style={{
              margin: 16,
              color: theme.palette.primary.main,
              maxHeight: 100,
              overflow: "auto",
              wordBreak: "break-all",
            }}
          >
            {error?.message}
          </div>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleClose}
          >
            Return to App
          </Button>
        </Box>
      );
    }
    if (isLoading) {
      return (
        <Box
          sx={{
            ...style,
            background: `url(${loadingBg})`,
            width: 550,
            height: 270,
            fontSize: "28px",
            color: theme.palette.secondary.main,
            fontWeight: 700,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="row">
            Awaiting Confirmation...
            <CircularProgress sx={{ marginLeft: "15px" }} />
          </div>
        </Box>
      );
    }

    return (
      <Box sx={style}>
        <div className="cardTitle">Review Transaction</div>
        <div style={{ fontSize: "20px", fontWeight: 400 }}>
          {message || `${action} ${_.get(contract, "symbol", "")}`}
        </div>

        <div>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!write}
            onClick={() => write?.({ args, from: address, value })}
          >
            Confirm in Wallet
          </Button>
        </div>
      </Box>
    );
  };
  return (
    <Modal open={Boolean(txData)} onClose={handleClose}>
      {renderContent()}
    </Modal>
  );
}
