import React, { useEffect, useState } from "react";
import { fetchDashboard } from "./redux/dashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid } from "@mui/material";
import logo from "assets/img/head.svg";
import CustomInput from "components/CustomInput/CustomInput";
import { tokens } from "features/configure";
import Social from "./components/Social";
import { useAccount } from "wagmi";
import ReviewTx from "./components/ReviewTx";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTheme } from "@mui/material";
import CustomPopover from "components/CustomPopover/CustomPopover";

import { ConnectButton } from "@rainbow-me/rainbowkit";
import _ from "lodash";

export default function Uncle() {
  const dashboardData = useSelector((state) => {
    return state.dashboard;
  });

  const [stakeInput, setStakeInput] = useState("");
  const [txData, setTxData] = useState("");
  const { address, isConnected } = useAccount();

  const dispatch = useDispatch();
  const theme = useTheme();
  const uncleCVXBalance = _.get(dashboardData, "data.balances.uncleCVX", 0);

  const renderInfo = (title, value, desc) => {
    const sx = { fontSize: "16px", fontWeight: 400, lineHeight: "24px" };
    return (
      <>
        <Grid item xs={12} md={6} sx={sx} className="startRow">
          {title}
          {desc && (
            <CustomPopover
              desc={desc}
              icon={
                <HelpOutlineIcon
                  style={{ color: theme.palette.primary.main, marginLeft: 3 }}
                />
              }
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ ...sx, textAlign: "right", marginBottom: "8px" }}
        >
          {value}
        </Grid>
      </>
    );
  };

  return (
    <div
      style={{
        position: "relative",
        maxWidth: "100%",
        paddingTop: 20,
        margin: "0 auto",
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 1000,
          backdropFilter: "blur(5px)",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ color: "white", fontSize: "3em" }}>Coming Soon...</span>
      </div>

      {txData && <ReviewTx txData={txData} handleClose={() => setTxData("")} />}
      <div
        className="card"
        style={{ maxWidth: "650px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            {" "}
            <div className="cardTitle">Stake UNCLE</div>
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
            <img src={logo} style={{ height: 87 }} />
          </Grid>
        </Grid>

        <CustomInput
          tokens={[tokens["UNCLE"]]}
          value={stakeInput}
          onChange={setStakeInput}
          balance={uncleCVXBalance}
        />
        <Button
          variant="contained"
          style={{ marginTop: 20 }}
          fullWidth
          color="primary"
        >
          Stake
        </Button>
        <hr style={{ marginBottom: "18px" }} />
        <Grid container>
          {renderInfo("vAPR", "-", "...")}
          {renderInfo("TVL", "-", "...")}
          {renderInfo(
            "$UNCLE token",
            <a target="_blank" rel="noreferrer" href="">
              0xabc...xxa
            </a>
          )}
          {renderInfo(
            "$UNCLE locker contract",
            <a target="_blank" rel="noreferrer" href="">
              0xabc...xxa
            </a>
          )}
        </Grid>

        <div style={{ marginTop: "20px", color: "#D0D0D0" }}>
          <ul>
            <li>
              vlUNCLE earns a portion of the performance and redemption fees.
            </li>
            <li>
              Vote-locked $UNCLE (vlUNCLE) is employed to cast votes on the
              allocation of $UNCLE and various proposals.
            </li>
            <li>
              To participate, users need to vote-lock their $UNCLE into vlUNCLE.
              This involves time-locking $UNCLE tokens for a period exceeding 16
              weeks.
            </li>
          </ul>
        </div>
      </div>
      <Social white />
    </div>
  );
}
