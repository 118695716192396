import React, { useState } from "react";
import { TextField, Grid, Button, Select, MenuItem } from "@mui/material";

import BigNumber from "bignumber.js";
import _ from "lodash";

export default function CustomInput(props) {
  const {
    value,
    onChange,
    tokens = [],
    balance = 0,
    setSelectedToken = () => {},
    selectedToken = _.get(tokens, "[0]", ""),
  } = props;

  function byDecimals(number, tokenDecimals = 18) {
    const decimals = new BigNumber(10).exponentiatedBy(tokenDecimals);
    return new BigNumber(number).dividedBy(decimals);
  }

  const inputFinalVal = (value, total, tokenDecimals = 18) => {
    let inputVal = Number(value.replaceAll(",", ""));
    return inputVal > total
      ? byDecimals(total, 0).toFormat(tokenDecimals)
      : value;
  };
  let inputReg = /[a-z]/i;
  const inputLimitPass = (value, tokenDecimals) => {
    let valueArr = value.split(".");
    return !(
      inputReg.test(value) ||
      (valueArr.length === 2 && valueArr[1].length > tokenDecimals)
    );
  };

  const setMaximumInput = () => {
    const total = balance;
    if (total > 0) {
      const amount = new BigNumber(total).toFormat();
      onChange(amount.replaceAll(",", ""));
    }
  };

  const changeInputValue = (event) => {
    let value = event.target.value;
    const total = balance;
    if (!inputLimitPass(value)) {
      return;
    }

    onChange(inputFinalVal(value, total));
  };

  return (
    <div className="lightCard">
      <Grid container alignItems={"flex-start"} spacing={1}>
        <Grid xs={12} md={6} item>
          {tokens.length > 1 ? (
            <Select
              value={_.get(selectedToken, "address")}
              onChange={(e) => {
                setSelectedToken(_.find(tokens, { address: e.target.value }));
              }}
              fullWidth
              size="small"
            >
              {tokens.map((token) => {
                return (
                  <MenuItem
                    key={_.get(token, "symbol")}
                    value={_.get(token, "address")}
                    className="startRow"
                  >
                    <img src={_.get(token, "imgUrl")} className="icon" />
                    {_.get(token, "symbol")}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <div
              style={{
                background: "rgba(255, 255, 255, 0.5)",
                borderRadius: 57,
                height: 50,
                color: "#04283B",
                fontSize: 20,
                fontWeight: 500,
                padding: "6px 12px",
              }}
            >
              <img src={_.get(tokens[0], "imgUrl")} className="icon" />
              {_.get(tokens[0], "symbol")}
            </div>
          )}

          <div
            style={{
              marginTop: 6,
              lineHeight: "200%",
            }}
          >
            Balance: {new BigNumber(balance).toFormat(3)}
          </div>
        </Grid>
        <Grid xs={12} md={6} item>
          <div>
            <TextField
              value={value}
              onChange={changeInputValue}
              fullWidth
              type="number"
              variant="standard"
              inputProps={{ min: 0, style: { textAlign: "right" } }}
              InputProps={{ disableUnderline: true }}
            />
          </div>

          <div style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="text"
              size="small"
              onClick={setMaximumInput}
              style={{ fontWeight: 700, margin: "6px 3px" }}
            >
              MAX
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
