import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Modal, Box, IconButton } from "@mui/material";
import secondary from "assets/img/secondary.png";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  color: "#FFFFFF",
  left: "50%",
  overflow: "auto",
  maxHeight: "90%",
  transform: "translate(-50%, -50%)",
  maxWidth: "1000px",
  background: `url(${secondary})`,
  boxShadow: 24,
  borderRadius: "10px",
  p: "45px",
};

export default function CustomModal({ content, title, open, setOpen }) {
  const handleClose = () => setOpen(false);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="cardTitle betweenRow">
          {title}
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </div>
        <div>{content}</div>
      </Box>
    </Modal>
  );
}
