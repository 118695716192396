import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import router from "./route";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon, localhost } from "wagmi/chains";
import { Provider } from "react-redux";
import store from "common/rootReducer";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";

// const chains = [mainnet];
const projectId = "964dc9937f480b5ee3c7bc55d4a2e844"; //walletConnet project ID

const { chains, publicClient } = configureChains(
  [mainnet],
  [
    alchemyProvider({ apiKey: "NMfny0oE8hAQ5tueVKfzX8tpqAIUPeM3" }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Uncle.Bond",
  projectId: projectId,
  chains,
});

// const { publicClient } = configureChains(chains, [
//   alchemyProvider({ apiKey: "NMfny0oE8hAQ5tueVKfzX8tpqAIUPeM3" }),
//   w3mProvider({ projectId }),
// ]);
const wagmiConfig = createConfig({
  autoConnect: true,
  // connectors: w3mConnectors({ projectId, chains }),
  connectors,
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

function Root() {
  return (
    <Provider store={store}>
      <SnackbarProvider>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider chains={chains}>
            <RouterProvider router={router} />
          </RainbowKitProvider>
        </WagmiConfig>
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      </SnackbarProvider>
    </Provider>
  );
}

export default Root;
